import { FeedbackT } from "@interfaces/feedback";
import fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { QAData, QAItemT } from "@interfaces/question-answer";

class QAService {
  static async CreateQuestionToSpecialist(
    formData: FormData,
    cb: ResCallback<FeedbackT>
  ) {
    try {
      const res = await fetcher.post<FeedbackT>(
        "/question-to-specialist",
        formData
      );

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      console.error("error");
      // @ts-ignore
      return cb(err);
    }
  }

  static async getAll() {
    const res = await fetcher.get<QAData[]>(`/question-answer`);
    return res.data;
  }

  static async create(data: QAItemT, cb: ResCallback<QAData>) {
    try {
      const res = await fetcher.post<QAData>(`/question-answer`, data);
      cb(null, res);
    } catch (err) {
      cb(err);
    }
  }

  static async update(id: string, data: Object, cb: ResCallback<string>) {
    try {
      const res = await fetcher.patch<string>(`/question-answer/${id}`, data);
      cb(null, res);
    } catch (err) {
      cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<string>) {
    try {
      const res = await fetcher.delete<string>(`/question-answer/${id}`);
      return cb(null, res);
    } catch (err) {
      return cb(err);
    }
  }
}

export default QAService;

import "@scss/components/reset.scss";
import "react-responsive-modal/styles.css";
import "@scss/app.scss";

import { $Auth, checkAuth } from "@store/auth-store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import AuthUserRoutes from "./AuthUserRoutes";
import Loader from "@ui/indicators/loader";

import { Modal } from "react-responsive-modal";
import SigningDocuments from "@pages/signing-documents";
import { Toaster } from "react-hot-toast";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useGetDeviceType } from "@utils/use-get-device-type";
import { ErrorBoundary } from "feature/error-boundary/ErrorBoundary";
import { AuthPage } from "feature/auth";
import { ProjectNewsWrapper } from "./ProjectNewsWrapper";
import { ForbiddenWarningModal } from "@modals/modal-items/forbidden-warning-modal";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";

const App = () => {
  const isAuth = useStore($Auth);
  const history = useHistory();
  const location = useLocation();
  const { modal, modalComponent, close } = useModal();
  const ModalContent = modalComponent.component;
  const permissions = useStore($UserAddPermissions);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            staleTime: 1000,
            // отключил потому что в основном, здесь все запросы по кнопке проходят, а не автоматом
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useGetDeviceType();

  // CHECKING AUTHORIZATION
  useEffect(() => {
    if (isAuth === null) {
      checkAuth();
    }
    if (
      isAuth === false &&
      !location.pathname.includes("/signature") &&
      location.pathname !== "/login"
    ) {
      history.push("/login");
    }
    if (isAuth && location.pathname.includes("/login")) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, history]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#00B856",
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <Modal open={modal.display} onClose={close} center>
          <ModalContent />
        </Modal>
        {/* {permissions.hasPermission(ACTIONS.projectNews_allowedToShow) && isAuth !== null && (
          <ProjectNewsWrapper />
        )} */}
        <ForbiddenWarningModal />

        <ErrorBoundary>
          <Switch>
            {/* <Home /> */}
            <Route exact path={"/signature"} component={SigningDocuments} />
            <Route path={"/"}>
              {isAuth === null ? (
                <Loader className="on_site_visit_loader" />
              ) : !isAuth ? (
                <AuthPage />
              ) : (
                <AuthUserRoutes />
              )}
            </Route>
          </Switch>
        </ErrorBoundary>
        <Toaster
          toastOptions={{
            duration: 5000,
          }}
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;

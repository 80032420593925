import {
  EventStateStoreT,
  Modules,
  UserAdditionalPermissions,
} from "@interfaces/common";
import {
  Permission,
  RolesNPermissionsT,
} from "@interfaces/store/users-store-types";
import { createEffect, createEvent, createStore } from "effector";

import { Permissions } from "@utils/api-tools";
import RolesPermissionsService from "@services/roles-permissions-service";
import { UserDataT } from "@interfaces/user";

export const resetAllStates = createEvent(
  "Resetting all stores(needed when user logs out or changes company)"
);
export const setUserData = createEvent<UserDataT | false>();
export const resetUserData = createEvent();

export const $User = createStore<UserDataT | null | false>(null)
  .on(setUserData, (state, newData) => {
    if (typeof newData === "object") {
      newData.roleName = GetUserRoleName(newData.roleId);
      setTimeout(() => setUserRole(newData!.roleId));
    }
    return newData;
  })
  .reset(resetUserData);

// USER ADDITIONAL PERMISSIONS
export const setUserAddPermissions = createEvent<UserAdditionalPermissions>();
export const setUserRole = createEvent<UserRoleEnum>();
export const setModule = createEvent<Modules>();

export const $UserAddPermissions = createStore<Permissions>(new Permissions())
  .on(setUserAddPermissions, (permissionsClass, permissions) => {
    return new Permissions(permissions, permissionsClass.module);
  })
  .on(setUserRole, (oldState, newRole) => {
    oldState.setRole(newRole);
    return new Permissions(oldState.permissions, oldState.module);
  })
  .on(setModule, (oldState, module) => {
    return new Permissions(oldState.permissions, oldState.module);
  })
  .reset(resetUserData);

// ROLES PERMISSIONS

export const setRolesPermissions = createEvent<any>();
export const pushToRolesPermissions = createEvent<any>();
export const changeRolePermission = createEvent<{
  roleId: number;
  permission: { roleId: number } & Permission;
}>();

export const $RolesPermissions = createStore<RolesNPermissionsT | null>(null)
  .on(setRolesPermissions, (state, newData) => {
    return newData;
  })
  .on(pushToRolesPermissions, (state, newData) => {
    state?.roles.push(newData);
    return state;
  })
  .on(changeRolePermission, (rolesAndPermissions, dataToChange) => {
    if (!rolesAndPermissions) return null;
    rolesAndPermissions.roles = rolesAndPermissions.roles.map((role) => {
      if (role.id === dataToChange.roleId) {
        const indexOfPermission = role.permissions
          .map((p) => p.id)
          .indexOf(dataToChange.permission.id);
        if (indexOfPermission > -1) {
          role.permissions.splice(indexOfPermission, 1);
        } else {
          role.permissions = [...role.permissions, dataToChange.permission];
        }
      }
      return role;
    });
    return rolesAndPermissions;
  })
  .reset(resetUserData);

export const setRolesPermissionsFetched = createEvent<boolean>();
export const setRolesPermissionsStates =
  createEvent<Partial<EventStateStoreT & { isFetchedCompany: boolean }>>();
// export const setRolesPermissionsError = createEvent<boolean>()
// export const serRolesPermissionsStates = createEvent<EventStateStoreT>()

export const $RolesPermissionsStates = createStore<
  EventStateStoreT & { isFetchedCompany?: boolean }
>({ isLoading: false, error: false, isFetched: false, isFetchedCompany: false })
  .on(setRolesPermissionsFetched, (oldState, newState) => ({
    ...oldState,
    isFetched: newState,
  }))
  .on(setRolesPermissionsStates, (oldState, newState) => ({
    ...oldState,
    ...newState,
  }))
  .reset(resetUserData);

export const fetchRolesPermissions = createEffect(
  async ({
    exactCompany = false,
    companyId = 0,
    priority = 3,
  }: {
    exactCompany?: boolean;
    companyId?: number;
    priority?: number;
  }) => {
    if (exactCompany) {
      setRolesPermissionsStates({ isLoading: true, isFetched: false });
    } else {
      setRolesPermissionsStates({ isLoading: true, isFetchedCompany: false });
    }
    RolesPermissionsService.GetRolesNPermissions(
      exactCompany,
      companyId,
      priority,
      (err, res) => {
        if (err || !res) {
          setRolesPermissions();
          setRolesPermissionsStates({
            isLoading: false,
            error: true,
            isFetched: false,
          });
        }
        if (!res) return false;
        setRolesPermissions({
          roles: res.data.roles,
          permissions: res.data.permissions,
        });
        if (exactCompany) {
          setRolesPermissionsStates({
            isLoading: false,
            error: false,
            isFetchedCompany: true,
          });
        } else {
          setRolesPermissionsStates({
            isLoading: false,
            error: false,
            isFetched: true,
          });
        }
      }
    );
  }
);
// USER DATA STATES
export const setUserLoading = createEvent<boolean>();
export const setUserError = createEvent<boolean>();
export const serUserStates = createEvent<EventStateStoreT>();

export const $UserDataStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setUserLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setUserError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(serUserStates, (_, newState) => newState)
  .reset(resetUserData);

// UTILS
export enum UserRoleEnum {
  SuperAdmin = 777,
  SuperAdminMinus = 1,
  Admin = 2,
  Client = 4,
  Worker = 5,
}

export enum UserPriorityEnum {
  Owner = 0,
  SuperAdmin = 1,
  Admin = 2,
  Client = 3,
  Worker = 4,
}

export const GetUserRoleName = (roleId: number) => {
  switch (roleId) {
    case UserRoleEnum.SuperAdmin:
      return "Владелец";
    case UserRoleEnum.SuperAdminMinus:
      return "Суперадмин";
    case UserRoleEnum.Admin:
      return "Администратор";
    // case UserRoleEnum.Admin: return 'Специалист ОТ'
    case UserRoleEnum.Client:
      return "Клиент";
    case UserRoleEnum.Worker:
      return "Специалист ОТ";
    default:
      return "Клиент";
  }
};

export const GetRolePriorityName = (priority: number) => {
  switch (priority) {
    case 0:
      return "Владелец";
    case 1:
      return "Суперадмин";
    case 2:
      return "Администратор";
    case 3:
      return "Клиент";
    case 4:
      return "Специалист ОТ";
    default:
      return "";
  }
};

export enum ACTIONS {
  // Личный кабинет
  personalAccount_allowedToEdit = "personalAccount_allowedToEdit",
  // Пользователи
  users_allowedToShow = "users_allowedToShow",
  users_allowedToCreate = "users_allowedToCreate",
  users_allowedToEdit = "users_allowedToEdit",
  // Роли
  roles_allowedToShow = "roles_allowedToShow",
  roles_levelFirstRoles_allowedToCreate = "roles_levelFirstRoles_allowedToCreate",
  roles_levelFirstRoles_allowedToEdit = "roles_levelFirstRoles_allowedToEdit",
  roles_levelFirstRoles_allowedToAssign = "roles_levelFirstRoles_allowedToAssign",
  roles_levelSecondRoles_allowedToCreate = "roles_levelSecondRoles_allowedToCreate",
  roles_levelSecondRoles_allowedToEdit = "roles_levelSecondRoles_allowedToEdit",
  roles_levelSecondRoles_allowedToAssign = "roles_levelSecondRoles_allowedToAssign",
  roles_levelThirdRoles_allowedToCreate = "roles_levelThirdRoles_allowedToCreate",
  roles_levelThirdRoles_allowedToEdit = "roles_levelThirdRoles_allowedToEdit",
  roles_levelThirdRoles_allowedToAssign = "roles_levelThirdRoles_allowedToAssign",
  roles_levelFourthRoles_allowedToCreate = "roles_levelFourthRoles_allowedToCreate",
  roles_levelFourthRoles_allowedToEdit = "roles_levelFourthRoles_allowedToEdit",
  roles_levelFourthRoles_allowedToAssign = "roles_levelFourthRoles_allowedToAssign",
  // Компании
  companies_allowedToCreate = "companies_allowedToCreate",
  companies_allowedToEdit = "companies_allowedToEdit",
  companies_allowedToDelete = "companies_allowedToDelete",
  companies_allowedToAssignUsers = "companies_allowedToAssignUsers",
  // Компания - рабочее пространство
  companyWorkspace_groups_allowedToCreate = "companyWorkspace_groups_allowedToCreate",
  companyWorkspace_groups_allowedToEdit = "companyWorkspace_groups_allowedToEdit",
  companyWorkspace_groups_allowedToDelete = "companyWorkspace_groups_allowedToDelete",
  companyWorkspace_directories_allowedToCreate = "companyWorkspace_directories_allowedToCreate",
  companyWorkspace_directories_allowedToEdit = "companyWorkspace_directories_allowedToEdit",
  companyWorkspace_directories_allowedToArchive = "companyWorkspace_directories_allowedToArchive",
  companyWorkspace_directories_allowedToDelete = "companyWorkspace_directories_allowedToDelete",
  companyWorkspace_directories_allowedToRestoreFromArchive = "companyWorkspace_directories_allowedToRestoreFromArchive",
  companyWorkspace_files_allowedToCreate = "companyWorkspace_files_allowedToCreate",
  companyWorkspace_files_allowedToEdit = "companyWorkspace_files_allowedToEdit",
  companyWorkspace_files_allowedToArchive = "companyWorkspace_files_allowedToArchive",
  companyWorkspace_files_allowedToDelete = "companyWorkspace_files_allowedToDelete",
  companyWorkspace_files_allowedToRestoreFromArchive = "companyWorkspace_files_allowedToRestoreFromArchive",
  companyWorkspace_allowedToDistributionByDepartmentsAndPositions = "companyWorkspace_allowedToDistributionByDepartmentsAndPositions",
  companyWorkspace_allowedToSendForSignature = "companyWorkspace_allowedToSendForSignature",
  // Компания - Шаблоны документов
  companyDocumentTemplates_allowedToShow = "companyDocumentTemplates_allowedToShow",
  companyDocumentTemplates_allowedToShowArchive = "companyDocumentTemplates_allowedToShowArchive",
  companyDocumentTemplates_groups_allowedToCreate = "companyDocumentTemplates_groups_allowedToCreate",
  companyDocumentTemplates_groups_allowedToEdit = "companyDocumentTemplates_groups_allowedToEdit",
  companyDocumentTemplates_groups_allowedToDelete = "companyDocumentTemplates_groups_allowedToDelete",
  companyDocumentTemplates_directories_allowedToCreate = "companyDocumentTemplates_directories_allowedToCreate",
  companyDocumentTemplates_directories_allowedToEdit = "companyDocumentTemplates_directories_allowedToEdit",
  companyDocumentTemplates_directories_allowedToArchive = "companyDocumentTemplates_directories_allowedToArchive",
  companyDocumentTemplates_directories_allowedToDelete = "companyDocumentTemplates_directories_allowedToDelete",
  companyDocumentTemplates_directories_allowedToRestoreFromArchive = "companyDocumentTemplates_directories_allowedToRestoreFromArchive",
  companyDocumentTemplates_files_allowedToCreate = "companyDocumentTemplates_files_allowedToCreate",
  companyDocumentTemplates_files_allowedToEdit = "companyDocumentTemplates_files_allowedToEdit",
  companyDocumentTemplates_files_allowedToArchive = "companyDocumentTemplates_files_allowedToArchive",
  companyDocumentTemplates_files_allowedToDelete = "companyDocumentTemplates_files_allowedToDelete",
  companyDocumentTemplates_files_allowedToRestoreFromArchive = "companyDocumentTemplates_files_allowedToRestoreFromArchive",
  // Компания - штатное расписание
  companyStaffing_departments_allowedToCreate = "companyStaffing_departments_allowedToCreate",
  companyStaffing_departments_allowedToEdit = "companyStaffing_departments_allowedToEdit",
  companyStaffing_departments_allowedToDelete = "companyStaffing_departments_allowedToDelete",
  companyStaffing_positions_allowedToCreate = "companyStaffing_positions_allowedToCreate",
  companyStaffing_positions_allowedToEditPositions = "companyStaffing_positions_allowedToEditPositions",
  companyStaffing_positions_allowedToDelete = "companyStaffing_positions_allowedToDelete",
  companyStaffing_employees_allowedToCreate = "companyStaffing_employees_allowedToCreate",
  companyStaffing_employees_allowedToEdit = "companyStaffing_employees_allowedToEdit",
  companyStaffing_employees_allowedToArchive = "companyStaffing_employees_allowedToArchive",
  companyStaffing_employees_allowedToRestoreFromArchive = "companyStaffing_employees_allowedToRestoreFromArchive",
  companyStaffing_employees_allowedToDeleteFromArchive = "companyStaffing_employees_allowedToDeleteFromArchive",
  // Компания - календарь событий
  companyEventsCalendar_allowedToCreate = "companyEventsCalendar_allowedToCreate",
  companyEventsCalendar_allowedToEdit = "companyEventsCalendar_allowedToEdit",
  companyEventsCalendar_allowedToDelete = "companyEventsCalendar_allowedToDelete",
  // Компания - план работы специалиста
  companySpecialistWorkPlan_allowedToShow = "companySpecialistWorkPlan_allowedToShow",
  companySpecialistWorkPlan_groups_allowedToCreate = "companySpecialistWorkPlan_groups_allowedToCreate",
  companySpecialistWorkPlan_groups_allowedToDelete = "companySpecialistWorkPlan_groups_allowedToDelete",
  companySpecialistWorkPlan_tasks_allowedToCreate = "companySpecialistWorkPlan_tasks_allowedToCreate",
  companySpecialistWorkPlan_tasks_allowedToSendToEventsCalendar = "companySpecialistWorkPlan_tasks_allowedToSendToEventsCalendar",
  companySpecialistWorkPlan_tasks_allowedToEdit = "companySpecialistWorkPlan_tasks_allowedToEdit",
  companySpecialistWorkPlan_tasks_allowedToDelete = "companySpecialistWorkPlan_tasks_allowedToDelete",
  // Компания - контроль подписания документов
  companyDocumentSigningControl_allowedToShow = "companyDocumentSigningControl_allowedToShow",
  companyDocumentSigningControl_allowedToCreate = "companyDocumentSigningControl_allowedToCreate",
  companyDocumentSigningControl_allowedToEdit = "companyDocumentSigningControl_allowedToEdit",
  // Компания - предписания
  companyPrescriptions_allowedToCreate = "companyPrescriptions_allowedToCreate",
  companyPrescriptions_allowedToEdit = "companyPrescriptions_allowedToEdit",
  companyPrescriptions_allowedToDelete = "companyPrescriptions_allowedToDelete",
  // Компания - контроль выполнения процедур
  companyProcedureControls_allowedToShow = "companyProcedureControls_allowedToShow",
  companyProcedureControls_allowedToEdit = "companyProcedureControls_allowedToEdit",
  // Компания - заявки на обучение
  companyApplicationsForTraining_allowedToShow = "companyApplicationsForTraining_allowedToShow",
  companyApplicationsForTraining_allowedToCreate = "companyApplicationsForTraining_allowedToCreate",
  companyApplicationsForTraining_allowedToEdit = "companyApplicationsForTraining_allowedToEdit",
  // Учебный центр
  educationalCenter_allowedToShow = "educationalCenter_allowedToShow",
  educationalCenter_materials_allowedToCreate = "educationalCenter_materials_allowedToCreate",
  educationalCenter_materials_allowedToEdit = "educationalCenter_materials_allowedToEdit",
  educationalCenter_materials_allowedToDelete = "educationalCenter_materials_allowedToDelete",
  educationalCenter_externalTraining_allowedToCreateApplicationsForTraining = "educationalCenter_externalTraining_allowedToCreateApplicationsForTraining",
  // Новости разработки
  developmentNews_allowedToShow = "developmentNews_allowedToShow",
  developmentNews_allowedToCreate = "developmentNews_allowedToCreate",
  developmentNews_allowedToEdit = "developmentNews_allowedToEdit",
  developmentNews_allowedToDelete = "developmentNews_allowedToDelete",
  // Новости законодательства
  legislationNews_allowedToShow = "legislationNews_allowedToShow",
  legislationNews_allowedToCreate = "legislationNews_allowedToCreate",
  legislationNews_allowedToEdit = "legislationNews_allowedToEdit",
  legislationNews_allowedToDelete = "legislationNews_allowedToDelete",
  // Полезные материалы
  usefulMaterials_allowedToShow = "usefulMaterials_allowedToShow",
  usefulMaterials_allowedToCreate = "usefulMaterials_allowedToCreate",
  usefulMaterials_allowedToEdit = "usefulMaterials_allowedToEdit",
  usefulMaterials_allowedToDelete = "usefulMaterials_allowedToDelete",
  // Ответственность
  responsibility_directories_allowedToCreate = "responsibility_directories_allowedToCreate",
  responsibility_directories_allowedToEdit = "responsibility_directories_allowedToEdit",
  responsibility_directories_allowedToDelete = "responsibility_directories_allowedToDelete",
  responsibility_files_allowedToCreate = "responsibility_files_allowedToCreate",
  responsibility_files_allowedToEdit = "responsibility_files_allowedToEdit",
  responsibility_files_allowedToDelete = "responsibility_files_allowedToDelete",
  // Юридическая информация
  legalInformation_directories_allowedToCreate = "legalInformation_directories_allowedToCreate",
  legalInformation_directories_allowedToEdit = "legalInformation_directories_allowedToEdit",
  legalInformation_directories_allowedToDelete = "legalInformation_directories_allowedToDelete",
  legalInformation_files_allowedToCreate = "legalInformation_files_allowedToCreate",
  legalInformation_files_allowedToEdit = "legalInformation_files_allowedToEdit",
  legalInformation_files_allowedToDelete = "legalInformation_files_allowedToDelete",
  // Судебная практика
  judicialPractice_allowedToShow = "judicialPractice_allowedToShow",
  judicialPractice_allowedToCreate = "judicialPractice_allowedToCreate",
  judicialPractice_allowedToEdit = "judicialPractice_allowedToEdit",
  judicialPractice_allowedToDelete = "judicialPractice_allowedToDelete",
  // Оплата
  payment_tariffs_allowedToChoose = "payment_tariffs_allowedToChoose",
  payment_tariffs_allowedToChange = "payment_tariffs_allowedToChange",
  // Помощь специалиста
  specialistHelp_allowedToShow = "specialistHelp_allowedToShow",
  specialistHelp_questions_allowedToAsk = "specialistHelp_questions_allowedToAsk",
  specialistHelp_answers_allowedToPrepare = "specialistHelp_answers_allowedToPrepare",
  specialistHelp_answers_allowedToEdit = "specialistHelp_answers_allowedToEdit", // NO FUNC
  specialistHelp_answers_allowedToArchive = "specialistHelp_answers_allowedToArchive", // NO FUNC
  specialistHelp_answers_allowedToDelete = "specialistHelp_answers_allowedToDelete", // NO FUNC
  // Справочники
  directories_allowedToShow = "directories_allowedToShow",
  directories_allowedToCreate = "directories_allowedToCreate",
  directories_allowedToEdit = "directories_allowedToEdit",
  directories_allowedToDelete = "directories_allowedToDelete",
  directories_allowedToConfirmChanges = "directories_allowedToConfirmChanges",
  directories_allowedToReverseChanges = "directories_allowedToReverseChanges",
  // Техподдержка
  support_questions_allowedToAsk = "support_questions_allowedToAsk",
  support_answers_allowedToPrepare = "support_answers_allowedToPrepare",
  // Новости проекта
  projectNews_allowedToShow = "projectNews_allowedToShow",
  projectNews_allowedToCreate = "projectNews_allowedToCreate",
  projectNews_allowedToEdit = "projectNews_allowedToEdit",
  projectNews_allowedToDelete = "projectNews_allowedToDelete",
  // Шаблоны документов
  documentTemplates_allowedToShow = "documentTemplates_allowedToShow",
  documentTemplates_allowedToShowArchive = "documentTemplates_allowedToShowArchive",
  documentTemplates_groups_allowedToCreate = "documentTemplates_groups_allowedToCreate",
  documentTemplates_groups_allowedToEdit = "documentTemplates_groups_allowedToEdit",
  documentTemplates_groups_allowedToDelete = "documentTemplates_groups_allowedToDelete",
  documentTemplates_directories_allowedToCreate = "documentTemplates_directories_allowedToCreate",
  documentTemplates_directories_allowedToEdit = "documentTemplates_directories_allowedToEdit",
  documentTemplates_directories_allowedToArchive = "documentTemplates_directories_allowedToArchive",
  documentTemplates_directories_allowedToDelete = "documentTemplates_directories_allowedToDelete",
  documentTemplates_directories_allowedToRestoreFromArchive = "documentTemplates_directories_allowedToRestoreFromArchive",
  documentTemplates_files_allowedToCreate = "documentTemplates_files_allowedToCreate",
  documentTemplates_files_allowedToEdit = "documentTemplates_files_allowedToEdit",
  documentTemplates_files_allowedToArchive = "documentTemplates_files_allowedToArchive",
  documentTemplates_files_allowedToDelete = "documentTemplates_files_allowedToDelete",
  documentTemplates_files_allowedToRestoreFromArchive = "documentTemplates_files_allowedToRestoreFromArchive",

  // Вопрос ответ
  questionAnswer_allowedToShow = 'questionAnswer_allowedToShow',
  questionAnswer_allowedToCreate = 'questionAnswer_allowedToCreate',
  questionAnswer_allowedToEdit = 'questionAnswer_allowedToEdit',
  questionAnswer_allowedToDelete = 'questionAnswer_allowedToDelete',
  // -------------------------------------------------------------------------------------------------------------------
}
const PERMISSION_NAMES = {
  // Личный кабинет
  personalAccount_allowedToEdit: "Редактирование данных",
  // Пользователи
  users_allowedToShow: "Видимость",
  users_allowedToCreate: "Создание",
  users_allowedToEdit: "Редактирование",
  // Роли
  roles_allowedToShow: "Видимость",
  roles_levelFirstRoles_allowedToCreate: "Создание ролей 1 уровня",
  roles_levelFirstRoles_allowedToEdit: "Редактирование ролей 1 уровня",
  roles_levelFirstRoles_allowedToAssign: "Назначение ролей 1 уровня",
  roles_levelSecondRoles_allowedToCreate: "Создание ролей 2 уровня",
  roles_levelSecondRoles_allowedToEdit: "Редактирование ролей 2 уровня",
  roles_levelSecondRoles_allowedToAssign: "Назначение ролей 2 уровня",
  roles_levelThirdRoles_allowedToCreate: "Создание ролей 3 уровня",
  roles_levelThirdRoles_allowedToEdit: "Редактирование ролей 3 уровня",
  roles_levelThirdRoles_allowedToAssign: "Назначение ролей 3 уровня",
  roles_levelFourthRoles_allowedToCreate: "Создание ролей 4 уровня",
  roles_levelFourthRoles_allowedToEdit: "Редактирование ролей 4 уровня",
  roles_levelFourthRoles_allowedToAssign: "Назначение ролей 4 уровня",
  // Компании
  companies_allowedToCreate: "Создание",
  companies_allowedToEdit: "Редактирование",
  companies_allowedToDelete: "Удаление",
  companies_allowedToAssignUsers: "Назначение пользователей",
  // Компания - рабочее пространство
  companyWorkspace_groups_allowedToCreate: "Создание групп",
  companyWorkspace_groups_allowedToEdit: "Редактирование групп",
  companyWorkspace_groups_allowedToDelete: "Удаление групп",
  companyWorkspace_directories_allowedToCreate: "Создание папок",
  companyWorkspace_directories_allowedToEdit: "Редактирование папок",
  companyWorkspace_directories_allowedToArchive: "Архивация папок",
  companyWorkspace_directories_allowedToDelete: "Удаление папок",
  companyWorkspace_directories_allowedToRestoreFromArchive:
    "Восстановление папок из архива",
  companyWorkspace_files_allowedToCreate: "Добавление документов",
  companyWorkspace_files_allowedToEdit: "Редактирование документов",
  companyWorkspace_files_allowedToArchive: "Архивация документов",
  companyWorkspace_files_allowedToDelete: "Удаление документов",
  companyWorkspace_files_allowedToRestoreFromArchive:
    "Восстановление документов из архива",
  companyWorkspace_allowedToDistributionByDepartmentsAndPositions:
    "Распределение по подразделениям и должностям",
  companyWorkspace_allowedToSendForSignature: "Отправка на подписание",
  // Компания - Шаблоны документов
  companyDocumentTemplates_allowedToShow: "Видимость",
  companyDocumentTemplates_allowedToShowArchive: "Видимость архива",
  companyDocumentTemplates_groups_allowedToCreate: "Создание групп",
  companyDocumentTemplates_groups_allowedToEdit: "Редактирование групп",
  companyDocumentTemplates_groups_allowedToDelete: "Удаление групп",
  companyDocumentTemplates_directories_allowedToCreate: "Создание папок",
  companyDocumentTemplates_directories_allowedToEdit: "Редактирование папок",
  companyDocumentTemplates_directories_allowedToArchive: "Архивация папок",
  companyDocumentTemplates_directories_allowedToDelete: "Удаление папок",
  companyDocumentTemplates_directories_allowedToRestoreFromArchive:
    "Восстановление папок из архива",
  companyDocumentTemplates_files_allowedToCreate: "Добавление документов",
  companyDocumentTemplates_files_allowedToEdit: "Редактирование документов",
  companyDocumentTemplates_files_allowedToArchive: "Архивация документов",
  companyDocumentTemplates_files_allowedToDelete: "Удаление документов",
  companyDocumentTemplates_files_allowedToRestoreFromArchive:
    "Восстановление документов из архива",
  // Компания - штатное расписание
  companyStaffing_departments_allowedToCreate: "Добавление подразделений",
  companyStaffing_departments_allowedToEdit: "Редактирование подразделений",
  companyStaffing_departments_allowedToDelete: "Удаление подразделений",
  companyStaffing_positions_allowedToCreate: "Добавление должностей",
  companyStaffing_positions_allowedToEditPositions: "Редактирование должностей",
  companyStaffing_positions_allowedToDelete: "Удаление должностей",
  companyStaffing_employees_allowedToCreate: "Добавление сотрудников",
  companyStaffing_employees_allowedToEdit: "Редактирование сотрудников",
  companyStaffing_employees_allowedToArchive: "Архивация сотрудников",
  companyStaffing_employees_allowedToRestoreFromArchive:
    "Восстановление сотрудников из архива",
  companyStaffing_employees_allowedToDeleteFromArchive:
    "Удаление сотрудников из архива",
  // Компания - календарь событий
  companyEventsCalendar_allowedToCreate: "Добавление событий",
  companyEventsCalendar_allowedToEdit: "Редактирование событий",
  companyEventsCalendar_allowedToDelete: "Удаление событий",
  // Компания - план работы специалиста
  companySpecialistWorkPlan_allowedToShow: "Видимость",
  companySpecialistWorkPlan_groups_allowedToCreate: "Создание категорий",
  companySpecialistWorkPlan_groups_allowedToDelete: "Удаление категорий",
  companySpecialistWorkPlan_tasks_allowedToCreate: "Создание задач",
  companySpecialistWorkPlan_tasks_allowedToSendToEventsCalendar:
    "Отправка задач в календарь событий",
  companySpecialistWorkPlan_tasks_allowedToEdit: "Редактирование задач",
  companySpecialistWorkPlan_tasks_allowedToDelete: "Удаление задач",
  // Компания - контроль подписания документов
  companyDocumentSigningControl_allowedToShow: "Видимость",
  companyDocumentSigningControl_allowedToCreate: "Создание",
  companyDocumentSigningControl_allowedToEdit: "Редактирование",
  // Компания - предписания
  companyPrescriptions_allowedToCreate: "Создание",
  companyPrescriptions_allowedToEdit: "Редактирование",
  companyPrescriptions_allowedToDelete: "Удаление",
  // Компания - контроль выполнения процедур
  companyProcedureControls_allowedToShow: "Видимость",
  companyProcedureControls_allowedToEdit: "Редактирование",
  // Компания - заявки на обучение
  companyApplicationsForTraining_allowedToShow: "Видимость",
  companyApplicationsForTraining_allowedToCreate: "Создание",
  companyApplicationsForTraining_allowedToEdit: "Редактирование",
  // Учебный центр
  educationalCenter_allowedToShow: "Видимость",
  educationalCenter_materials_allowedToCreate:
    "Добавление обучающих материалов",
  educationalCenter_materials_allowedToEdit:
    "Редактирование обучающих материалов",
  educationalCenter_materials_allowedToDelete: "Удаление обучающих материалов",
  educationalCenter_externalTraining_allowedToCreateApplicationsForTraining:
    "Создание заявок на обучение",
  // Новости разработки
  developmentNews_allowedToShow: "Видимость",
  developmentNews_allowedToCreate: "Создание",
  developmentNews_allowedToEdit: "Редактирование",
  developmentNews_allowedToDelete: "Удаление",
  // Новости законодательства
  legislationNews_allowedToShow: "Видимость",
  legislationNews_allowedToCreate: "Создание",
  legislationNews_allowedToEdit: "Редактирование",
  legislationNews_allowedToDelete: "Удаление",
  // Полезные материалы
  usefulMaterials_allowedToShow: "Видимость",
  usefulMaterials_allowedToCreate: "Создание",
  usefulMaterials_allowedToEdit: "Редактирование",
  usefulMaterials_allowedToDelete: "Удаление",
  // Ответственность
  responsibility_directories_allowedToCreate: "Создание папок",
  responsibility_directories_allowedToEdit: "Редактирование папок",
  responsibility_directories_allowedToDelete: "Удаление папок",
  responsibility_files_allowedToCreate: "Создание файлов",
  responsibility_files_allowedToEdit: "Редактирование файлов",
  responsibility_files_allowedToDelete: "Удаление файлов",
  // Юридическая информация
  legalInformation_directories_allowedToCreate: "Создание папок",
  legalInformation_directories_allowedToEdit: "Редактирование папок",
  legalInformation_directories_allowedToDelete: "Удаление папок",
  legalInformation_files_allowedToCreate: "Создание файлов",
  legalInformation_files_allowedToEdit: "Редактирование файлов",
  legalInformation_files_allowedToDelete: "Удаление файлов",
  // Судебная практика
  judicialPractice_allowedToShow: "Видимость",
  judicialPractice_allowedToCreate: "Создание",
  judicialPractice_allowedToEdit: "Редактирование",
  judicialPractice_allowedToDelete: "Удаление",
  // Оплата
  payment_tariffs_allowedToChoose: "Выбор тарифа",
  payment_tariffs_allowedToChange: "Изменение тарифа",
  // Помощь специалиста
  specialistHelp_allowedToShow: "Видимость",
  specialistHelp_questions_allowedToAsk: "Задавание вопросов",
  specialistHelp_answers_allowedToPrepare: "Подготовка ответов",
  specialistHelp_answers_allowedToEdit: "Редактирование ответов",
  specialistHelp_answers_allowedToArchive: "Архивация ответов",
  specialistHelp_answers_allowedToDelete: "Удаление ответов",
  // Справочники
  directories_allowedToShow: "Видимость",
  directories_allowedToCreate: "Добавление",
  directories_allowedToEdit: "Редактирование",
  directories_allowedToDelete: "Удаление",
  directories_allowedToConfirmChanges: "Подтверждение изменений",
  directories_allowedToReverseChanges: "Отмена изменений",
  // Техподдержка
  support_questions_allowedToAsk: "Задавание вопросов",
  support_answers_allowedToPrepare: "Подготовка ответов",
  // Новости проекта
  projectNews_allowedToShow: "Видимость",
  projectNews_allowedToCreate: "Создание",
  projectNews_allowedToEdit: "Редактирование",
  projectNews_allowedToDelete: "Удаление",
  // Шаблоны документов
  documentTemplates_allowedToShow: "Видимость",
  documentTemplates_allowedToShowArchive: "Видимость архива",
  documentTemplates_groups_allowedToCreate: "Создание категорий",
  documentTemplates_groups_allowedToEdit: "Редактирование групп",
  documentTemplates_groups_allowedToDelete: "Удаление категорий",
  documentTemplates_directories_allowedToCreate: "Создание папок",
  documentTemplates_directories_allowedToEdit: "Редактирование папок",
  documentTemplates_directories_allowedToArchive: "Архивация папок",
  documentTemplates_directories_allowedToDelete: "Удаление папок",
  documentTemplates_directories_allowedToRestoreFromArchive: "Восстановление папок из архива",
  documentTemplates_files_allowedToCreate: "Создание файлов",
  documentTemplates_files_allowedToEdit: "Редактирование файлов",
  documentTemplates_files_allowedToArchive: "Архивация документов",
  documentTemplates_files_allowedToDelete: "Удаление файлов",
  documentTemplates_files_allowedToRestoreFromArchive: "Восстановление документов из архива",
  // Вопрос ответ
  questionAnswer_allowedToShow: 'Видимость',
  questionAnswer_allowedToCreate: 'Создание',
  questionAnswer_allowedToEdit: 'Редактирование',
  questionAnswer_allowedToDelete:'Удаление',
  // -------------------------------------------------------------------------------------------------------------------
};
export const getPermissionName = (permission: ACTIONS) => {
  return PERMISSION_NAMES[permission];
};

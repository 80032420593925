import { FetchingQADataT, QAData } from "@interfaces/question-answer";
import { createEffect, createEvent, createStore } from "effector";
import QAService from "@services/questionAnswer-service";

const setQAItems = createEvent<QAData[] | false>("Изменение состояния");
const resetQAItems = createEvent("Сброс состояния");

export const $QAItems = createStore<QAData[] | null | false>(null)
  .on(setQAItems, (state, newData) => newData)
  .reset(resetQAItems);

export const fetchQAItems = createEffect(async () => {
  try {
    const response = await QAService.getAll();

    setQAItems(response);
  } catch (err) {
    console.error(err);
    setQAItems(false);
  }
});

import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Route, Switch } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import Company from "@pages/company/company";
import ControlRequestTrainingPage from "@pages/control-request-training-page/control-request-training-page";
import DirectorySystems from "@pages/directory-systems/directory-systems";
import { EditRequestTraining } from "feature/request-training/edit-request-training";
import EditRequestTrainingPage from "@pages/EditRequestTrainingPage/control-request-training-page";
import FeedbackItem from "@pages/feedback/feedback-item";
import Feedbacks from "@pages/feedback/feedback";
import GosKey from "@pages/goskey/goskey";
import Header from "@components/header";
import Home from "@pages/home";
import LegalInformation from "@pages/legal-information";
import NewsAndPracticeRoutes from "@pages/news";
import Payment from "@pages/payment/payment";
import Permissions from "@pages/permissions/permissions";
import ProcedureControlCompany from "@pages/procedure-control-company/procedure-control-company";
import { ProfilePage } from "@pages/profile";
import QuestionAnswer from "@pages/question-answer";
import Responsibility from "@pages/responsibility";
import SpecialistPlanPage from "@pages/specialist-plan/specialist-plan";
import StudyCenterRoutes from "@pages/study-center";
import UserGuide from "@pages/userGuide";
import UsersRoutes from "@pages/users";
import { useStore } from "effector-react";
import { MenuPanel } from "@components/menu-panel";
import DocumentTemplatesPage from "@pages/document-templates-page/document-templates-page";
import { DocumentTemplatesArchive } from "feature/document-templates-archive";

const AuthUserRoutes: React.FC = () => {
  const permissions = useStore($UserAddPermissions);
  const [menuDisplay, setMenuDisplay] = useState<boolean>(false);

  const resizeHandler = useCallback(() => {
    const clientWidth = document.documentElement.clientWidth;

    if (clientWidth > 1024 && menuDisplay) {
      setMenuDisplay(false);
    }
  }, [menuDisplay]);

  // LISTENER FOR CLOSING MENU PANEL, WHEN CLIENT WITH > 1024 && MENU PANEL IS OPEN
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);

  return (
    <>
      <Header
        menuDisplay={menuDisplay}
        onBurgerClick={() => setMenuDisplay(!menuDisplay)}
      />
      <div className={`content-wrapper ${menuDisplay ? "with-menu" : ""}`}>
        <MenuPanel onItemClick={() => setMenuDisplay(false)} />
        <Switch>
          <Route exact path={"/"} component={Home} />
          {permissions.hasPermission(ACTIONS.users_allowedToShow) && (
            <Route
              path={["/settings/users", "/settings/roles"]}
              component={UsersRoutes}
            />
          )}
          <Route path={["/company", "/company/*"]} component={Company} />
          <Route path={"/specialist-plan"} component={SpecialistPlanPage} />
          <Route exact path={"/profile"} component={ProfilePage} />
          <Route exact path={"/user-guide"} component={UserGuide} />
          <Route
            path={"/procedure-control-company"}
            component={ProcedureControlCompany}
          />
          {permissions.hasPermission(ACTIONS.directories_allowedToShow) && (
            <Route path="/directories/*" component={DirectorySystems} />
          )}
          <Route path={["/study-center"]} component={StudyCenterRoutes} />
          {permissions.hasPermission(
            ACTIONS.documentTemplates_allowedToShow
          ) && (
            <Route
              exact
              path={"/document-templates"}
              component={DocumentTemplatesPage}
            />
          )}
          {permissions.hasPermission(
            ACTIONS.documentTemplates_allowedToShowArchive
          ) && (
            <Route
              exact
              path="/document-templates/archive"
              component={DocumentTemplatesArchive}
            />
          )}
          <Route
            path={[
              "/legislation-news",
              "/practices",
              "/practice",
              "/help",
              "/development-news",
            ]}
            component={NewsAndPracticeRoutes}
          />
          {permissions.hasPermission(
            ACTIONS.projectNews_allowedToShow
          ) && (
            <Route
              path="/project-news"
              component={NewsAndPracticeRoutes}
            />
          )}
          <Route exact path={"/responsibility"} component={Responsibility} />
          <Route
            exact
            path={"/legal-information"}
            component={LegalInformation}
          />
          <Route path={"/payment"} component={Payment} />
          <Route path={"/permissions"} component={Permissions} />
          {permissions.hasPermission(ACTIONS.specialistHelp_allowedToShow) && (
            <Route exact path={"/question-answer"}>
              <QuestionAnswer />
            </Route>
          )}
          <Route path={"/goskey"} component={GosKey} />
          <Route
            exact
            path={"/control-request-training"}
            component={ControlRequestTrainingPage}
          >
            <ControlRequestTrainingPage />
          </Route>
          <Route
            exact
            path={"/control-request-training/:id"}
            component={EditRequestTraining}
          >
            <EditRequestTrainingPage />
          </Route>
          <Route exact path={"/feedback"}>
            <Feedbacks />
          </Route>
          <Route exact path={"/feedback/:id"} component={FeedbackItem} />
        </Switch>
      </div>
    </>
  );
};

export default AuthUserRoutes;

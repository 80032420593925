import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useEffect, useState } from "react";
import { Trash, UploadSimple } from "@phosphor-icons/react";

import { DocumentArchiveTablePropsT } from "@interfaces/company/archive";
import { Tooltip } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import moment from "moment";
import style from "@scss/pages/company/company-workspace.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useStore } from "effector-react";
import { useChoiceState } from "@hooks/useChoiceState";
import {
  useArchivedDocumentTemplatesFilesQuery,
  useDeleteDocumentTemplatesDirectoryMutation,
  useDeleteDocumentTemplatesFileMutation,
  useDocumentTemplatesGroupsQuery,
  useUnarchiveDocumentTemplatesDirectoryMutation,
  useUnarchiveDocumentTemplatesFileMutation,
} from "@lib/document-templates";
import { BodyNormal } from "@ui/fonts";
import { TableTopPanel } from "@components/common";
import DocumentTemplatesGroups from "@components/company/document-templates/document-templates-groups";

moment.locale("ru");

const getSorting = (sort: 0 | 10 | 20): "ASC" | "DESC" | undefined => {
  if (sort === 10) return "DESC";
  if (sort === 20) return "ASC";
  return undefined;
};

const ArchiveTemplatesTable: React.FC<DocumentArchiveTablePropsT> = ({
  className,
}) => {
  const permissions = useStore($UserAddPermissions);

  const { groups } = useDocumentTemplatesGroupsQuery();

  const [activeGroupId, setActiveGroupId] = useState(0);
  const [sortOptionValue, setSortOptionsValue] = useState<0 | 10 | 20>(0);

  const unarchiveDirectoryMutation =
    useUnarchiveDocumentTemplatesDirectoryMutation();
  const unarchiveFileMutation = useUnarchiveDocumentTemplatesFileMutation();
  const deleteDirectoryMutation = useDeleteDocumentTemplatesDirectoryMutation();
  const deleteFileMutation = useDeleteDocumentTemplatesFileMutation();
  const updatedOrder = getSorting(sortOptionValue);

  const { files, isLoading, error } = useArchivedDocumentTemplatesFilesQuery(
    activeGroupId,
    { updatedOrder }
  );

  const dirChoice = useChoiceState(files?.directories ?? [], (dir) => dir.id);
  const fileChoice = useChoiceState(files?.files ?? [], (file) => file.id);

  useEffect(() => {
    if (groups && groups.length > 0 && !activeGroupId) {
      setActiveGroupId(groups[0].id);
    }
  }, [groups, activeGroupId]);

  const handleUnarchiveDirectory = (directoryId: number) => {
    unarchiveDirectoryMutation.mutate({
      groupId: activeGroupId,
      directoryId,
    });
  };

  const handleUnarchiveFile = (fileId: number, id: number) => {
    unarchiveFileMutation.mutate({
      groupId: activeGroupId,
      directoryId: id,
      fileId,
    });
  };

  const handleDeleteFile = (fileId: number, directoryId: number) => {
    if (!fileId) return;

    deleteFileMutation.mutate({
      groupId: activeGroupId,
      directoryId: directoryId,
      fileId,
    });
  };

  const handleDeleteDirectory = (directoryId: number) => {
    if (!directoryId) return;

    deleteDirectoryMutation.mutate({
      groupId: activeGroupId,
      directoryId: directoryId,
    });
  };

  const handleUnarchiveItems = () => {
    for (const dir of dirChoice.selectedItems) {
      unarchiveDirectoryMutation.mutate({
        groupId: activeGroupId,
        directoryId: dir.id,
      });
    }

    for (const file of fileChoice.selectedItems) {
      unarchiveFileMutation.mutate({
        groupId: activeGroupId,
        directoryId: file.directory?.id ?? 0,
        fileId: file.id,
      });
    }
  };

  const handleDeleteItems = () => {
    for (const dir of dirChoice.selectedItems) {
      deleteDirectoryMutation.mutate({
        groupId: activeGroupId,
        directoryId: dir.id,
      });
    }

    for (const file of fileChoice.selectedItems) {
      deleteFileMutation.mutate({
        groupId: activeGroupId,
        directoryId: file.directory?.id ?? 0,
        fileId: file.id,
      });
    }
  };

  const tableDirectoriesContent = files?.directories?.map((item) => {
    const checkboxId = `dir-${item.id}-checkbox`;

    const firstRow = (
      <label className={tableStyle.column_fixed_height}>
        <input
          type="checkbox"
          className={tableStyle.checkbox_item}
          hidden
          id={checkboxId}
          checked={dirChoice.getIsItemSelected(item)}
          onChange={(e) => dirChoice.selectById(item, e.target.checked)}
        />
        <label htmlFor={checkboxId}>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <svg
          className={style.file_icon}
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
        >
          <path
            d="M0 11.7761V30.3969C0 31.452 0.806906 32.259 1.86209 32.259H34.1379C35.1931 32.259 36 31.452 36 30.3969V11.7761C36 10.721 35.1931 9.91406 34.1379 9.91406H1.86209C0.806906 9.91406 0 10.7831 0 11.7761Z"
            fill="#F7B84E"
          />
          <path
            d="M32.8963 9.93103C32.8963 8.56549 31.779 7.44829 30.4135 7.44829H20.4825L17.379 3.72412H3.7239C2.66872 3.72412 1.86182 4.59311 1.86182 5.58621V9.93103H32.8963Z"
            fill="#E4A12F"
          />
        </svg>
        <label htmlFor={`key`} className={style.nameFile}>
          {item?.directory
            ? `.../${item.directory.name}/${item.name}`
            : item.name}
        </label>
      </label>
    );

    return (
      <tr key={item.id}>
        <td>
          <div className={clsx(style.folder)}>{firstRow}</div>
        </td>
        <td>
          <p className={clsx(style.document_date)}>
            {moment(item.updatedAt).format("DD.MM.YYYY hh:mm")}
          </p>
          <div className={style.removeAndArchive}>
            <div>
              {permissions.hasPermission(
                ACTIONS.documentTemplates_directories_allowedToRestoreFromArchive
              ) ? (
                <Tooltip title="Вернуть" placement={"top"}>
                  <button onClick={() => handleUnarchiveDirectory(item.id)}>
                    <WrapperSVG color="green-light">
                      <UploadSimple
                        size={24}
                        className={clsx(style.edit_icon)}
                      />
                    </WrapperSVG>
                  </button>
                </Tooltip>
              ) : null}
            </div>
            <div>
              {permissions.hasPermission(
                ACTIONS.documentTemplates_directories_allowedToDelete
              ) ? (
                <Tooltip title="Удалить" placement={"top"}>
                  <button onClick={() => handleDeleteDirectory(item.id)}>
                    <Trash size={24} className={clsx(style.edit_icon)} />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </td>
      </tr>
    );
  });

  const tableFilesContent = files?.files?.map((item) => {
    const checkboxId = `file-${item.id}-checkbox`;

    const firstRow = (
      <label className={clsx(tableStyle.column_fixed_height)}>
        <input
          type="checkbox"
          className={clsx(tableStyle.checkbox_item)}
          hidden
          id={checkboxId}
          checked={fileChoice.getIsItemSelected(item)}
          onChange={(e) => fileChoice.selectById(item, e.target.checked)}
        />
        <label htmlFor={checkboxId}>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <svg
          className={clsx(style.file_icon)}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
            fill="#DFE3F1"
          />
          <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
          <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
        </svg>
        <span className={style.nameFile}>
          {item?.directory
            ? `.../${item.directory.name}/${item.name}`
            : item.name}
        </span>
      </label>
    );

    return (
      <tr key={item.id}>
        <td>{firstRow}</td>
        <td>
          <p className={clsx(style.document_date)}>
            {moment(item.updatedAt).format("DD.MM.YYYY hh:mm")}
          </p>
          <div className={style.removeAndArchive}>
            <div>
              {permissions.hasPermission(
                ACTIONS.documentTemplates_files_allowedToRestoreFromArchive
              ) ? (
                <Tooltip title="Вернуть" placement={"top"}>
                  <button
                    onClick={() =>
                      handleUnarchiveFile(item.id, item.directory?.id ?? 0)
                    }
                  >
                    <WrapperSVG color="green-light">
                      <UploadSimple
                        size={24}
                        className={clsx(style.edit_icon)}
                      />
                    </WrapperSVG>
                  </button>
                </Tooltip>
              ) : null}
            </div>
            <div>
              {permissions.hasPermission(
                ACTIONS.responsibility_files_allowedToDelete
              ) ? (
                <Tooltip title="Удалить" placement={"top"}>
                  <button
                    onClick={() =>
                      handleDeleteFile(item.id, item.directory?.id ?? 0)
                    }
                  >
                    <Trash size={24} className={clsx(style.edit_icon)} />
                  </button>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={clsx(tableStyle.base_table_container, className)}>
      <div className={style.archiveTableTop}>
        <div>
          <BodyNormal weight="bold">Группы</BodyNormal>
        </div>
        {groups && (
          <DocumentTemplatesGroups
            groups={groups}
            activeGroupId={activeGroupId}
            withEditButton={false}
            replaceHistory
            setActiveGroupId={setActiveGroupId}
          />
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <>
          <TableTopPanel
            hideSearchPanel
            onSelectOption={(e) => setSortOptionsValue(e.value as 10 | 20)}
            text={`Элементов: ${
              isLoading
                ? ""
                : (files?.directories?.length ?? 0) +
                  (files?.files?.length ?? 0)
            }`}
          />
          <p className={clsx(style.path_text)}></p>
          <table
            className={clsx(
              tableStyle.base_table,
              style.workspace_table,
              style.archiveTable
            )}
          >
            <thead>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      className={clsx(tableStyle.checkbox_item)}
                      hidden
                      id="dir-file-all-checkbox"
                      checked={
                        dirChoice.areAllItemsSelected &&
                        fileChoice.areAllItemsSelected
                      }
                      onChange={(e) => {
                        dirChoice.selectAll(e.target.checked);
                        fileChoice.selectAll(e.target.checked);
                      }}
                    />
                    <label htmlFor="dir-file-all-checkbox">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </label>
                    <label className={clsx(tableStyle.checkbox_label)}>
                      Название документа
                    </label>
                  </label>
                </td>
                <td>
                  <div className={style.archiveTableHeader__actions}>
                    {(dirChoice.selectedItems.length > 0 ||
                      fileChoice.selectedItems.length > 0) && (
                      <div className={style.archiveTableHeader__actions}>
                        <div>
                          {permissions.hasPermission(
                            ACTIONS.documentTemplates_files_allowedToRestoreFromArchive
                          ) ? (
                            <Tooltip title="Вернуть" placement={"top"}>
                              <button onClick={handleUnarchiveItems}>
                                <WrapperSVG color="green-light">
                                  <UploadSimple
                                    size={24}
                                    className={clsx(style.edit_icon)}
                                  />
                                </WrapperSVG>
                              </button>
                            </Tooltip>
                          ) : null}
                        </div>
                        <div>
                          {permissions.hasPermission(
                            ACTIONS.responsibility_files_allowedToDelete
                          ) ? (
                            <Tooltip title="Удалить" placement={"top"}>
                              <button onClick={handleDeleteItems}>
                                <Trash
                                  size={24}
                                  className={clsx(style.edit_icon)}
                                />
                              </button>
                            </Tooltip>
                          ) : null}
                        </div>
                      </div>
                    )}
                    Дата обновления
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {tableDirectoriesContent}
              {tableFilesContent}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ArchiveTemplatesTable;

import { BodyLight } from "@ui/fonts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import styles from "./new-employees-body-row.module.scss";
import {
  SingleTrainingRequestProgram,
  trainingRequestEducationForms,
} from "@interfaces/request-training";
import { useState } from "react";
import moment, { Moment } from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import toast from "react-hot-toast";

type NewEmployeesBodyRowPropsT = {
  index: number;
  program: SingleTrainingRequestProgram;
  isEditable?: boolean;
  onEducationFormChange: (form: string) => void;
  onTrainingPeriodChange: (timestamp: Date) => void;
};

export const NewEmployeesBodyRow: React.FC<NewEmployeesBodyRowPropsT> = ({
  index,
  program,
  isEditable = true,
  onEducationFormChange,
  onTrainingPeriodChange,
}) => {
  const permissions = useStore($UserAddPermissions);

  const [educationForm, setEducationForm] = useState(program.form == null ? 'Очная' : program.form);
  const [isDateError, setIsDateError] = useState(false);

  // здесь стоит `any` потому что проп `onChange` в селекте ниже не принимает стандартный `React.ChangeEvent<HTMLInputElement>` а требует кастомный тип `ChangeEvent<{name?: string; value: unknown}>`
  const handleFormChange = (e: any) => {
    const value = e.target.value as string;

    setEducationForm(value);
    onEducationFormChange(value);
  };

  const handlePeriodDateChange = (date: Moment | null) => {
    setIsDateError(false);

    if (date?.isBefore(moment())) {
      toast.error("Нельзя выбрать прошедшую дату");
      setIsDateError(true);
      return;
    }

    if (date?.isValid()) {
      onTrainingPeriodChange(date.toDate());
    }
  };

  return (
    <TableRow>
      <TableCell align="left">
        <div className={clsx(styles.table_row_center)}>
          <BodyLight>{++index}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.edit_and_save}>
          <div
            className={clsx(styles.table_row_center, styles.table_row_title)}
          >
            <BodyLight>{program.programTitle.slice(0, 150)}</BodyLight>
          </div>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          {isEditable ? (
            <Select
              defaultValue={educationForm}
              onChange={handleFormChange}
              disabled={
                !permissions.hasPermission(
                  ACTIONS.companyApplicationsForTraining_allowedToEdit
                )
              }
            >
              {trainingRequestEducationForms.map((item) => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}
            </Select>
          ) : (
            <BodyLight>{educationForm}</BodyLight>
          )}
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          <BodyLight>{program.students.length}</BodyLight>
        </div>
      </TableCell>
      <TableCell align="left">
        <div className={styles.table_row_center}>
          {isEditable ? (
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="ru"
            >
              <KeyboardDatePicker
                disabled={
                  !permissions.hasPermission(
                    ACTIONS.companyApplicationsForTraining_allowedToEdit
                  )
                }
                cancelLabel="Отменить"
                okLabel="ОК"
                disableToolbar
                variant="dialog"
                format="DD.MM.yyyy"
                minDate={moment()}
                minDateMessage=""
                error={isDateError}
                value={program.deadline ? moment(program.deadline) : null}
                onChange={handlePeriodDateChange}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <BodyLight>
              {program.deadline &&
                moment(program.deadline).format("DD.MM.YYYY")}
            </BodyLight>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

import { useMutation, useQueryClient } from "react-query";

import QAService from "@services/questionAnswer-service";
import { questionAnswerQueryKeys } from "./queryKeys";
import { QAItemT } from "@interfaces/question-answer";

export const useCreateQuestionAnswerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: QAItemT) => {
      return new Promise((resolve, reject) => {
        QAService.create(data, (err, res) => {
          if (err || !res) {
            return reject(err);
          }
          resolve(res.data);
        });
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(questionAnswerQueryKeys.all);
      },
    }
  );
};

import "@scss/pages/question-answer.scss";

import {
  $User,
  UserRoleEnum,
  setUserData
} from "@store/user-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ColorfulButton, Title } from "@components/common/common";
import React from "react";
import { selectColourStyles, selectTheme } from "@components/common/common";

import { BreadCrumb } from "@components/common";
import { GetMaxQAs } from "@utils/getMaxQAs";
import { QAData } from "@interfaces/question-answer";
import QAService from "@services/questionAnswer-service";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import { UserDataT } from "@interfaces/user";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { formatFileSize } from "@utils/formatFileSize";
import { Controller, useForm } from "react-hook-form";
import { fetchQAItems, $QAItems } from "@store/qusetion-answer-store";

const QATabs = ({ items }: { items: QAData[] | null | false }) => {
  const toggleTab = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => {
    const tab = e.target.closest(".qa-tab__item");
    tab.classList.toggle("active");
  };
  const { open } = useModal();
  const permissions = useStore($UserAddPermissions);

  let content;
  if(items){
    content = items.map((tab, idx) => {
      return (
        <div key={idx} className="qa-tab__item shadow-sm">
          <div className="qa-tab__trigger" onClick={toggleTab}>
            <img
              src="/img/static/green-arrow_drop_down.png"
              alt=""
              className="qa-tab__arrow"
            />
            <p className="qa__title" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', width: '100%' }}>
              {tab.title}
              {permissions.roleIsIn([UserRoleEnum.SuperAdmin]) && (
              <ColorfulButton
                text="Изменить"
                plusIcon={false}
                onClick={() =>
                  open("CreateQuestionAnswerModal", {
                    btnText: "Изменить",
                    modalData: {
                      modalTitle: "Редактирование вопроса",
                      editMode: true,
                      id: tab.id,
                      answerQuestion: tab.answer,
                      titleQuestion: tab.title,
                    },
                  })
                }
              />
            )}</p>
          </div>
          <div className="qa-tab__content">
            <div className="">{tab.answer}</div>
          </div>
        </div>
      );
    });
  }

  return <section className="qa-tab-items">
    {content}
    {permissions.roleIsIn([UserRoleEnum.SuperAdmin]) && (
      <ColorfulButton
        text="Добавить"
        plusIcon={true}
        onClick={() =>
          open("CreateQuestionAnswerModal", {
            btnText: "Добавить",
            modalData: { modalTitle: "Добавить вопрос" },
          })
        }
      />
    )}
  </section>;
};

// PAGE /---------------------------------------------------------------------------------\

const CATEGORIES = [
  "Основные документы",
  "Обучение по охране труда",
  "Инструктаж по охране труда",
  "Первая помощь",
  "Средства индивидуальной защиты",
  "Смывающие и обезвреживающие средства",
  "Медицинские осмотры",
  "Психиатрические освидетельствования",
  "Расследование несчастных случаев",
  "Электробезопасность",
  "Пожарная безопасность",
  "Работы на высоте",
  "Транспорт",
  "Погрузочно-разгрузочные работы",
  "Инструмент",
  "Стеллажи",
  "Прочие задачи",
];

// TODO: Type props for this component and maybe move it to a separate file
const LoadedFiles = ({ files, setSelectedFiles }: any) => {
  const content: any = [];

  for (let key in files) {
    const currentFile = files[key];

    if (!currentFile || !+currentFile.size) {
      break;
    }
    content.push(
      <div key={key} className="feedback_form_file_item">
        <div className="flex-n-c">
          <svg
            className="file_icon"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
              fill="#DFE3F1"
            />
            <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
            <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
          </svg>
          <span className="feedback_form_file_name">{currentFile.name}</span>
          <span className="feedback_form_file_size">
            ({formatFileSize(currentFile.size)})
          </span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => {
            let newFileList: any = {};

            for (let key2 in files) {
              // IF CURRENT FILE IS NOT EQUAL TO CLICKED FILE, THEN DONT DELETE A FILE FROM FILES LIST
              if (
                files[key2].lastModified !== currentFile.lastModified ||
                files[key2].size !== currentFile.size
              ) {
                newFileList[key2] = files[key2];
              }
            }
            setSelectedFiles({ ...newFileList });
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    );
  }
  return content;
};

type QAForm = {
  title: number;
  message: string;
  files: FileList | null;
};

const QuestionAnswer = () => {
  fetchQAItems();
  const QAItems = useStore($QAItems);
  const user = useStore($User) as UserDataT;
  const { open } = useModal();

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<QAForm>();

  const uploadedFiles = watch("files");

  const options = CATEGORIES.map((title, index) => ({
    label: title,
    value: index,
  }));

  const classes = useStyles();

  const onSubmit = handleSubmit((data) => {
    const maxQAs = GetMaxQAs(user.roleId);
    if (user.messagesSent >= maxQAs) {
      return open("NotificationModal", {
        modalData: {
          modalTitle: "",
          text: "У вас не осталось обращений, повысьте тариф, чтобы получить еще",
        },
      });
    }

    const formData = new FormData();
    formData.append(
      "title",
      options.find((item) => item.value === data.title)?.label ??
        options[0].label
    );
    formData.append("message", data.message);
    formData.append("email", user.email);
    formData.append("userId", `${user.id}`);
    if (uploadedFiles) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append("image", uploadedFiles[i]);
      }
    }

    QAService.CreateQuestionToSpecialist(formData, (err, res) => {
      if (err || !res) {
        open("NotificationModal", {
          modalData: {
            modalTitle: "",
            text: "При отправке запроса, произошла ошибка",
          },
        });
        return console.error(err?.message);
      }
      setUserData({ ...user, messagesSent: user.messagesSent + 1 });
      open("NotificationModal", {
        modalData: {
          modalTitle: "Спасибо за обращение! ",
          text: "Наш специалист свяжется с вами в ближайшее время.",
          text2: `Уважаемый пользователь, на вашем тарифе осталось обращений - <b>${
            maxQAs - user.messagesSent
          }</b>`,
          removeIcon: true,
        },
      });

      reset();
    });
  });
  const permissions = useStore($UserAddPermissions);

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Вопрос специалисту"]} />
          <Title text="Вопрос специалисту" />
        </div>
        <div className="wrapper">
          {permissions.hasPermission(
            ACTIONS.questionAnswer_allowedToShow
          ) && <QATabs items={QAItems} />}
          <>
            {permissions.hasPermission(
              ACTIONS.specialistHelp_questions_allowedToAsk
            ) && (
              <section className="qa-tab-form shadow-sm">
                <p className="qa__title">Задать вопрос специалисту</p>
                <form className={classes.root} onSubmit={onSubmit}>
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: "Обязательное поле",
                    }}
                    render={({ field }) => (
                      <Select
                        // closeMenuOnSelect={ true }
                        // components={ animatedComponents }
                        options={options}
                        noOptionsMessage={() => "Такой темы не найдено"}
                        isSearchable
                        placeholder={"Тема вопроса"}
                        styles={selectColourStyles(
                          {},
                          { isError: !!errors.title }
                        )}
                        theme={selectTheme}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        {...field}
                      />
                    )}
                  />
                  {/* <TextField label="Тема вопроса" name={'title'} variant="filled" required /> */}
                  <TextField
                    style={{ zIndex: 0 }}
                    label="Опишите Ваш вопрос"
                    variant="filled"
                    multiline
                    minRows={20}
                    error={!!errors.message}
                    helperText={errors.message?.message}
                    {...register("message", {
                      required: "Обязательное поле",
                    })}
                  />

                  <LoadedFiles
                    files={uploadedFiles}
                    setSelectedFiles={(files: any) => setValue("files", files)}
                  />

                  <div className="bottom-section">
                    <ColorfulButton
                      plusIcon={false}
                      text={"Отправить вопрос"}
                    />
                    {/* <Dropzone maxFiles={ 1 } onUpload={ setUploadedFiles } /> */}
                    <input
                      multiple
                      type="file"
                      id="feedback-file"
                      hidden
                      {...register("files")}
                    />
                    <label className="file-label" htmlFor="feedback-file">
                      <img src="/img/static/pin-icon.png" alt="" />
                      <span>Прикрепить файл</span>
                    </label>
                  </div>
                </form>
              </section>
            )}
          </>
        </div>
        {/*{*/}
        {/*   QAItems === null ? 'Loading...' :*/}
        {/*       QAItems === false ? 'Произошла ошибка' : <QATabs items={QAItems} />*/}
        {/*}*/}
      </div>
    </main>
  );
};

export default QuestionAnswer;

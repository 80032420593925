import {
  $DepartmentState,
  fetchDepartments,
} from "@store/company/departments-store";
import {
  $EmployeesStates,
  fetchEmployees,
} from "@store/company/employees-store";
import {
  $ProcedureTraining,
  FetchAllCategoriesForTrainingPrograms,
  FetchEmployeeProcedureTrainingCategories,
} from "@store/company/procedure-training-store";
import { $Warning, $WarningStates, FetchWarning } from "@store/warning-store";
import { BodyBold, BodyNormal } from "@ui/fonts";
import {
  DropDownDeadlineSelect,
  SimpleDropDownMultiSelect,
} from "@ui/drop-down";
import { FileLoadSVG, WarnSVG } from "@components/svgs";
import {
  Procedure,
  ProcedureTrainingQueryParams,
  procedureTrainingSortKeys,
} from "@interfaces/company/procedure";
import React, { memo, useEffect, useMemo, useState } from "react";
import {
  useTrainingProcedureGeneralsQuery,
  useTrainingProceduresQuery,
} from "@lib/procedures";

import { Button } from "@ui/button";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { CommonSearch } from "@ui/common-search";
import { DatePeriod } from "@ui/date-period";
import { DropDownSelect } from "@ui/drop-down-select";
import ProcedureControlService from "@services/procedure-control-service";
import ProcedureService from "@services/programs-service";
import { ProcedureTablesTrainig } from "../procedure-tables-trainig";
import { SELECTS_ID } from "@store/company/procedure-instrunctions-store";
import { TrainingProcedureParamsProvider } from "../training-params-context";
import { downloadBlob } from "@utils/downloadBlob";
import style from "@scss/pages/company/procedure-control.module.scss";
import { useDepartmentsGetAllQuery } from "@lib/departments-get-all/departments-get-all";
import { useEmployeesGetAllQuery } from "@lib/employees-get-all/employees-get-all";
import { usePositionsGetAllQuery } from "@lib/positions-get-all/positions-get-all";
import { useSorting } from "@hooks/useSorting";
import { useStore } from "effector-react";
import { СategoriesT } from "@interfaces/directory/version";
import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";
import { getProcedureControlParamsArray } from "@utils/getProcedureControlParamsArray";

const LIMIT = 20;

const getOffset = (page: number) => LIMIT * (page - 1);

type SelectListType = {
  id: number;
  title: string;
};
type SelectOneListType = {
  value: number;
  label: string;
};

const getOptionsList = <T,>(
  list: T[],
  getValue: (item: T) => SelectListType["id"],
  getLabel: (item: T) => SelectListType["title"]
): SelectListType[] => {
  return list.map((item) => ({
    id: getValue(item),
    title: getLabel(item),
  }));
};

interface LearningComponentsProps {
  withoutFilters?: boolean;
  withoutEmpty?: boolean;
  ignoredKeys?: (keyof Procedure)[];
  defaultFilters?: Partial<ProcedureTrainingQueryParams>;
  isGeneral?: boolean;
}

export const LearningComponents: React.FC<LearningComponentsProps> = memo(
  ({
    withoutFilters = false,
    withoutEmpty = false,
    defaultFilters,
    ignoredKeys,
    isGeneral = false,
  }) => {
    const { company } = useSelectedCompanyQuery();
    const { companies } = useCompaniesQuery();

    const warning = useStore($Warning);
    const warningState = useStore($WarningStates);
    const TYPE = "training";
    const directionLearning = useStore($ProcedureTraining);
    const [onModal, setOnModal] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [textDate, setTextDate] = useState<string>("");
    const [listIds, setListIds] = useState<string>("");
    const [lerningList, setLerningList] = useState<SelectListType[]>([]);
    const [choiceCategory, setChoiceCategory] = useState<СategoriesT[]>();
    const [search, setSearch] = useState<string>("");
    const [queryFilter, setQueryFilter] = useState<
      Omit<
        ProcedureTrainingQueryParams,
        "departments" | "positions" | "employees"
      >
    >(defaultFilters ?? {});
    const [selectedDepartments, setSelectedDepartments] = useState<
      SelectListType[]
    >([]);
    const [selectedPositions, setSelectedPositions] = useState<
      SelectListType[]
    >([]);
    const [selectedEmployees, setSelectedEmployees] = useState<
      SelectListType[]
    >([]);
    const [selectedCompanies, setSelectedCompanies] = useState<
      SelectListType[]
    >([]);
    const [selectedPrograms, setSelectedPrograms] = useState<SelectListType[]>(
      []
    );
    const [selectedRequire, setSelectedRequire] = useState<
      SelectOneListType
    >(defaultFilters?.isRequired === true ? { value: 1, label: 'Обязательно' } : {  value: 0, label: 'Не обязательно'  });
    const [choiceID, setChoiceID] = useState<number | null>(null);
    const companiesList: SelectListType[] = useMemo(() => {
      return (
        companies?.map((company) => ({
          id: company.id,
          title: company.name,
        })) ?? []
      );
    }, [companies]);

    const { data: getAllDepartments } = useDepartmentsGetAllQuery(
      {
        companies: selectedCompanies.map((c) => c.id),
      },
      true
    );
    const { data: getAllEmployees } = useEmployeesGetAllQuery(
      {
        companies: selectedCompanies.map((c) => c.id),
        departments: selectedDepartments.map((d) => d.title),
        positions: selectedPositions.map((p) => p.title),
      },
      true
    );
    const { data: getAllPositions } = usePositionsGetAllQuery(
      {
        companies: selectedCompanies.map((c) => c.id),
        departments: selectedDepartments.map((d) => d.title),
      },
      true
    );

    const [page, setPage] = useState(1);
    const sorting = useSorting(procedureTrainingSortKeys, "ASC");

    const employeesList = getOptionsList(
      getAllEmployees,
      (e) => e.id,
      (e) => e.name ?? String(e.id)
    );
    const departmentsList = getOptionsList(
      getAllDepartments,
      (d) => d.id,
      (d) => d.title ?? String(d.id)
    );
    const positionsList = getOptionsList(
      getAllPositions,
      (p) => p.id,
      (p) => p.title ?? String(p.id)
    );
    const requireList: SelectOneListType[] = [{ value: 1, label: 'Обязательно' }, { value: 0, label: 'Не обязательно' }];

    const trainingParams = {
      ...queryFilter,
      categories: choiceID ? [choiceID] : undefined,
      limit: LIMIT,
      offset: getOffset(page),
      searchString: search,
      departments: getProcedureControlParamsArray(
        selectedDepartments,
        departmentsList.length,
        isGeneral
      ),
      positions: getProcedureControlParamsArray(
        selectedPositions,
        positionsList.length,
        isGeneral
      ),
      employees: getProcedureControlParamsArray(
        selectedEmployees,
        employeesList.length,
        isGeneral
      ),
      companies: selectedCompanies.map((c) => c.id),
      programs: selectedPrograms.map((p) => p.id),
      ...sorting.getActiveSorting(),
    };
    const trainingControlsCompany = useTrainingProceduresQuery(
      trainingParams,
      isGeneral
    );
    const trainingControlsIsGeneral = useTrainingProcedureGeneralsQuery(
      trainingParams,
      isGeneral
    );

    const trainingControls =
      trainingControlsCompany ?? trainingControlsIsGeneral;

    const hasNextPage =
      (trainingControls.data?.[0]?.body?.length ?? 0) >= LIMIT;

    const handlerChangeFilter = (key: string, value: any) => {
      if (key === "deadline") {
        setQueryFilter((prevFilters) => ({
          ...prevFilters,
          deadline: value
            .split(";")
            .map((item: string) => item.split(","))
            .flat(),
        }));
        return;
      }

      if (value === "") {
        return setQueryFilter((prevFilters) => ({
          ...prevFilters,
          [key]: undefined,
        }));
      }

      const filtersToResetPage = [
        "departments",
        "positions",
        "employees",
        "companies",
        "programs",
      ];
      if (filtersToResetPage.includes(key) && page !== 1) {
        setPage(1);
      }

      switch(key){
        case 'departments':
          setSelectedPositions([]);
          setSelectedEmployees([]);
          return setSelectedDepartments(value);
        case 'positions':
          setSelectedEmployees([]);
          return setSelectedPositions(value);
        case 'employees':
          return setSelectedEmployees(value);
        case 'programs':
          return setSelectedPrograms(value);
        case 'companies':
          setSelectedDepartments([]);
          setSelectedPositions([]);
          setSelectedEmployees([]);
          return setSelectedCompanies(value);
      }

      setQueryFilter((prevFilters) => ({ ...prevFilters, [key]: value }));
    };

    const handlerChangeRequire = (value: any) => {
      setSelectedRequire(requireList.find((e) => (e.value === value)) as SelectOneListType);
      setQueryFilter((prevFilters) => ({ ...prevFilters, isRequired: !!value }));
    }

    const closeModalCalendar = () => setOnModal(false);
    const openModalCalendar = () => setOnModal(true);

    const { isFetched: isEmployeesFetched } = useStore($EmployeesStates);
    const { isFetched: isDepartmentsFetched } = useStore($DepartmentState);

    const areTablesEmpty = useMemo(() => {
      let isEmpty = false;
      return isEmpty;
    }, []);

    const handlerLerningList = (values?: SelectListType[]) => {
      let idsTrainingProgram = values && values.map((item) => item.id);
      ProcedureService.Programs(
        "training",
        idsTrainingProgram,
        true,
        (err, res) => {
          if (err || !res) {
            throw new Error(
              "При получении списка программ обучения категорий произошла ошибка"
            );
          }
          if (res.data) {
            const programs = res.data.map((item) => item.programs).flat();
            setLerningList(programs);
            if (company) {
              FetchWarning(company.id);
            }
          }
        }
      );
    };

    const dropDownData = useMemo(() => {
      const data = [
        {
          label: "Подразделение",
          list: departmentsList,
          type: "departments",
          value: selectedDepartments,
        },
        {
          label: "Должность",
          list: positionsList,
          type: "positions",
          value: selectedPositions,
        },
        {
          label: "Программа обучения",
          list: lerningList,
          type: "programs",
          value: selectedPrograms,
        },
        {
          label: "Сотрудники",
          list: employeesList,
          type: "employees",
          value: selectedEmployees,
        },
      ];

      if (isGeneral) {
        data.unshift({
          label: "Компания",
          list: companiesList,
          type: "companies",
          value: selectedCompanies,
        });
      }

      return data;
    }, [
      isGeneral,
      companiesList,
      departmentsList,
      employeesList,
      lerningList,
      positionsList,
      selectedCompanies,
      selectedPrograms,
      selectedDepartments,
      selectedPositions,
      selectedEmployees,
    ]);

    useEffect(() => {
      if (!defaultFilters?.employees) return;
      const ids = defaultFilters.employees.map((id) => parseInt(String(id)))
      const defaultEmployees = getAllEmployees.filter((item) => ids.includes(item.id))
      setSelectedEmployees(getOptionsList(
        defaultEmployees,
        (p) => p.id,
        (p) => p.name ?? String(p.id)
      ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters?.employees])

    useEffect(() => {
      if (!isEmployeesFetched) fetchEmployees();
      if (!isDepartmentsFetched) fetchDepartments();
    }, [isEmployeesFetched, isDepartmentsFetched]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => handlerLerningList(), []);

    useEffect(() => {
      const isEmployeePage = selectedEmployees.length === 1;
      if (isEmployeePage) {
        FetchEmployeeProcedureTrainingCategories(+selectedEmployees![0]);
      } else {
        FetchAllCategoriesForTrainingPrograms({ isIncludeChanges: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isGeneral || !company) return;

      setSelectedCompanies([{ id: company.id, title: company.name }]);
    }, [isGeneral, company]);

    useEffect(() => {
      if (directionLearning?.categories && listIds) {
        const stateListIDs = listIds
          .split(",")
          .map(Number)
          .filter((id) => id !== SELECTS_ID);
        const newDirectionLearning = directionLearning?.categories.filter(
          (obj) => stateListIDs.includes(obj.id)
        );

        setChoiceCategory(newDirectionLearning);
        setChoiceID(newDirectionLearning[0]?.id ?? null);
      }
      if (directionLearning?.categories && !listIds) {
        const allDirectionLearning = directionLearning?.categories?.filter(
          (item) => item.id !== SELECTS_ID
        );
        setChoiceCategory(allDirectionLearning);
        setChoiceID(allDirectionLearning[0]?.id ?? null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directionLearning?.categories, listIds]);

    useEffect(() => {
      setPage(1);
    }, [choiceID, queryFilter]);

    const handleFilterAndSearch = () => {
      handlerChange(listIds, queryFilter, search);
    };

    const handlerChange = (
      listIds: string,
      filter?: ProcedureTrainingQueryParams,
      search?: string
    ) => {
      setListIds(listIds);
      setQueryFilter(
        Object.assign({}, defaultFilters, {
          ...filter,
          searchString: search,
        })
      );
    };

    const getExcelFileName = (categoryId: number | null): string => {
      let filename = "";

      if (categoryId !== null) {
        const category = directionLearning.categories?.find(
          (category) => category.id === choiceID
        );
        if (category) {
          if (isGeneral) {
            filename = `Контроль обучения [${category.title}].xlsx`;
          } else {
            filename = `${company?.name} - Контроль обучения [${category.title}].xlsx`;
          }
        }

        return filename;
      }

      if (isGeneral) {
        filename = `Контроль обучения.xlsx`;
      } else {
        filename = `${company?.name} - Контроль обучения.xlsx`;
      }

      return filename;
    };

    const handleDownloadExcelFile = () => {
      const request = isGeneral
        ? ProcedureControlService.GetTrainingExcelFileForAllCompanies
        : ProcedureControlService.GetTrainingExcelFile;

      request(
        { ...trainingParams, offset: undefined, limit: undefined },
        (err, res) => {
          if (err || !res) return;

          const filename = getExcelFileName(choiceID);
          downloadBlob(res.data, filename);
        }
      );
    };

    const handleDownloadAllCategories = () => {
      const request = isGeneral
        ? ProcedureControlService.GetTrainingExcelFileForAllCompanies
        : ProcedureControlService.GetTrainingExcelFile;

      request(
        {
          ...trainingParams,
          categories: undefined,
          offset: undefined,
          limit: undefined,
        },
        (err, res) => {
          if (err || !res) return;

          const filename = getExcelFileName(null);
          downloadBlob(res.data, filename);
        }
      );
    };

    return (
      <TrainingProcedureParamsProvider value={trainingParams}>
        {!withoutFilters && (
          <div>
            <CommonSearch
              className="ml-auto"
              onChange={handleFilterAndSearch}
              setSearch={setSearch}
            />
          </div>
        )}
        {!warning?.isTrainingFill && !warningState.isLoading && (
          <div className={style.page_warning}>
            <WarnSVG />
            Заполните данные по обучениям сотрудников
          </div>
        )}
        {areTablesEmpty && (
          <div className={style.page_warning}>
            <WarnSVG />
            Укажите требуемые программы обучения для должности сотрудника
          </div>
        )}
        <div>
          {!withoutFilters && directionLearning && (
            <div className={style.direction_list}>
              <div
                className={style.direction_list_dowload}
                tabIndex={0}
                onClick={handleDownloadAllCategories}
              >
                <FileLoadSVG />
                Скачать все таблицы
              </div>
              <div
                className={style.direction_list_dowload}
                tabIndex={0}
                onClick={handleDownloadExcelFile}
              >
                <FileLoadSVG />
                Скачать текущую таблицу
              </div>
            </div>
          )}
          <div className={style.wrapper}>
            {!withoutFilters && (
              <>
                <div className={style.head_container}>
                  <div className={style.head_filter}>
                    {dropDownData.map(({ label, list, type, value }) => (
                      <SimpleDropDownMultiSelect
                        key={label}
                        label={label}
                        options={list}
                        emptyIsFull
                        value={value}
                        onChange={(values) => handlerChangeFilter(type, values)}
                      />
                    ))}
                    <div
                      onClick={openModalCalendar}
                      className={style.select_date}
                    >
                      <div className={style.select_label}>
                        Дата следующего обучения
                      </div>
                      {textDate}
                      <CalendarMonth
                        color="action"
                        className={style.select_icon}
                      />
                    </div>
                    <DatePeriod
                      active={onModal}
                      setTextDate={setTextDate}
                      closeModal={closeModalCalendar}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      type="date"
                      handlerChangeFilter={handlerChangeFilter}
                    />
                    <DropDownDeadlineSelect
                      type="deadline"
                      title="Дней до обучения"
                      handlerChangeFilter={handlerChangeFilter}
                    />
                    <DropDownSelect
                      className={style.selectRequire}
                      title={"К заполнению"}
                      options={requireList}
                      value={requireList.find((r) => r.value === selectedRequire.value)}
                      onChange={(option) => handlerChangeRequire(option.value)}
                    />
                  </div>
                  <Button onClick={handleFilterAndSearch}>
                    <BodyNormal>Фильтровать</BodyNormal>
                  </Button>
                </div>
              </>
            )}
            <div className={style.choise_programm}>
              {!!choiceCategory &&
                choiceCategory.map((item) => (
                  <Button
                    key={item.id}
                    color={choiceID !== item.id ? "white-green" : "green"}
                    borderColor={"green"}
                    onClick={() => setChoiceID(item.id)}
                  >
                    <BodyBold>{item.title}</BodyBold>
                  </Button>
                ))}
            </div>
            {!!choiceID && (
              <div className={style.table}>
                <ProcedureTablesTrainig
                  TYPE={TYPE}
                  withoutEmpty={withoutEmpty}
                  ignoredKeys={ignoredKeys}
                  sorting={sorting}
                  tables={trainingControls.data ?? []}
                  isLoading={trainingControls.isLoading}
                  offset={getOffset(page)}
                  page={page}
                  totalPages={hasNextPage ? page + 1 : page}
                  onPageChange={setPage}
                />
              </div>
            )}
          </div>
        </div>
      </TrainingProcedureParamsProvider>
    );
  }
);
